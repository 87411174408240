























import { Config } from 'client-website-ts-library/services';
import { Context } from 'client-website-ts-library/types';
import { ContextItemType, CustomContextItem } from 'client-website-ts-library/types/Context';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Form from './Forms/Form.vue';
import Modal from './Modal.vue';

@Component({
  components: {
    Modal,
    Form,
  },
})
export default class SuburbReport extends Vue {
  @Prop({ default: false })
  private isFormShown = false;

  @Prop({ default: 'Cairns' })
  private suburb!: string;

  private formData: Context | null = null;

  mounted() {
    this.formData = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.CustomData,
          Id: '',
          Key: 'Address',
          Value: '',
        },
        {
          Type: ContextItemType.CustomData,
          Id: '',
          Key: 'Suburb',
          Value: this.suburb,
        } as CustomContextItem,
      ],
    };
  }

  @Watch('suburb')
  updateForm() {
    alert(this.suburb);
  }

  @Watch('isFormShown')
  updateModal() {
    this.$emit('isFormShown', this.isFormShown);
  }

  handleFormSubmitted() {
    this.isFormShown = true;
  }
}
